/**
 * index.js
 * 
 * The main entrypoint for the Project Aerius Landing / Home Page.
 * Created for GRDE3016 - Internet Project Development, Curtun University,
 * Semester 2, 2020 by Francis Villarba for Assignment 3.
 * 
 * @author Francis Villarba <francis.villarba@student.curtin.edu.au>
 */

// Webpack Compatible Imports ----------------------------------------------- /

// Main Sass Styles File
import './scss/main.scss';
import './scss/index.scss';

// The JavaScript Animation Library
import anime from 'animejs/lib/anime.es';

// Functions ---------------------------------------------------------------- /